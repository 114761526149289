<template>
    <BreadcrumbVue />
    <section class="menu">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="lgpd">
                        <ul class="link-lgpd">
                            <li class="list-item" @click="changeSection('lgpd')">LGPD</li>
                            <li class="list-item" @click="changeSection('cookies')">Norma de cookies</li>
                            <li class="list-item" @click="changeSection('portal')">Termo de uso do portal</li>
                            <li class="list-item" @click="changeSection('privacidade')">Norma de privacidade</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sobre-page">
        <div class="container">
            <div class="row my-5">
                <div class="col-lg-12">
                    <div class="titulo-page">
                        <h2>
                            {{ activeSectionData.titulo }}
                        </h2>
                    </div>
                </div>

                <div class="col-lg-12">
                    <p class="text-sobre my-5" v-html="activeSectionData.texto"></p>
                </div>
            </div>
        </div>
    </section>

    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none"
        preserveAspectRatio="none">
        <path
            d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z"
            fill="#EEEEEE" fill-opacity="0.8" />
    </svg>
    <Popup />
    <FooterInternas />
</template>

<script>
import axios from 'axios';

const urlLGPD = 'https://api.cref20.org.br/wp-json/cref/v1/lgpd';
const urlCookies = 'https://api.cref20.org.br/wp-json/cref/v1/cookies';
const urlPortal = 'https://api.cref20.org.br/wp-json/cref/v1/portal';
const urlPrivacidade = 'https://api.cref20.org.br/wp-json/cref/v1/privacidade';

import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';
import Popup from '@/components/Popup/PopupComponent.vue';
export default {
    name: 'LGPDPage',
    components: {
        FooterInternas,
        BreadcrumbVue,
        Popup
    },
    watch: {
        $route() {
            this.scrollTop()
        }
    },
    data() {
        return {
            sections: {
                lgpd: { titulo: '', texto: '' },
                cookies: { titulo: '', texto: '' },
                portal: { titulo: '', texto: '' },
                privacidade: { titulo: '', texto: '' }
            },
            activeSection: 'lgpd'
        }
    },
    computed: {
        activeSectionData() {
            return this.sections[this.activeSection];
        }
    },
    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },
        changeSection(section) {
            this.activeSection = section;
        },
        fetchData(url, key, tituloKey, textoKey) {
            axios.get(url).then(response => {
                this.sections[key] = {
                    titulo: response.data[0].corpo[tituloKey],
                    texto: response.data[0].corpo[textoKey]
                };
            });
        }
    },
    mounted() {
        this.fetchData(urlLGPD, 'lgpd', 'titulo_lgpd', 'texto_lgpd');
        this.fetchData(urlCookies, 'cookies', 'titulo_cookies', 'texto_cookies');
        this.fetchData(urlPortal, 'portal', 'titulo_portal', 'texto_portal');
        this.fetchData(urlPrivacidade, 'privacidade', 'titulo_privacidade', 'texto_privacidade');
    },
    created() {
        this.scrollTop()
    }
}
</script>

<style scoped>
.link-lgpd {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 10px;
}

.list-item {
    text-decoration: none;
    color: #12575F;
    padding: 10px;
    border-radius: 10px;
    background: #E7E7E7;
    cursor: pointer;
}

.text-sobre {
    color: #313131;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sobre-page .titulo-page h2 {
    color: #313131;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sobre-page .container .row.center {
    justify-content: center;
}

.sobre-page img {
    width: 100%;
}

svg {
    width: 100%;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }

    .text-sobre {
        text-align: justify;
        padding: 10px;
    }
}
</style>