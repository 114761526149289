<template>
    <section class="info-vermelho">
        <div class="container">
            <div class="row">
                <div class="informacoes-header">
                    <img :src="require('@/assets/imgs/telicon.png')" alt="Ícone telefone" class="me-2">
                    <span>(79) 3214-6184 / (79) 30231052 | Atendimento: 8h30 às 15h30 por agendamento </span>
                </div>
            </div>
        </div>
    </section>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container">
            <router-link class="navbar-brand" to="/" aria-label="Início">
                <img :src="require('@/assets/imgs/logo_cref.webp')" alt="Logo CREF20">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul class="navbar-nav align-items-center">
                    <li class="nav-item">
                        <router-link class="nav-link" :class="{ active: $route.path === '/' }" aria-label="Início"
                            aria-current="page" to="/">Início</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Institucional
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><router-link class="dropdown-item" to="/sobre">Sobre o CREF20/SE</router-link></li>
                            <li><router-link class="dropdown-item" to="/diretoria">Diretoria</router-link></li>
                            <li><router-link class="dropdown-item" to="/conselheiros">Conselheiros</router-link></li>
                            <li><router-link class="dropdown-item" to="/colaboradores">Colaboradores</router-link></li>
                            <li><a class="dropdown-item" target="_blank"
                                    href="https://api.cref20.org.br/wp-content/uploads/2023/10/csu_confet_2018.pdf">Carta
                                    de Serviços</a></li>
                            <li><router-link class="dropdown-item" to="/eleicoes">Eleições</router-link></li>
                            <li><router-link class="dropdown-item" to="/prestacao-de-contas">Prestação de
                                    Contas</router-link></li>
                            <li><router-link class="dropdown-item" to="/lgpd">LGPD</router-link></li>

                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <router-link class="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Serviços
                        </router-link>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><router-link class="dropdown-item" to="/pessoa-fisica">Pessoa Física</router-link></li>
                            <li><router-link class="dropdown-item" to="/pessoa-juridica">Pessoa Jurídica</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <router-link class="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Fiscalização
                        </router-link>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><router-link class="dropdown-item" to="/importancia-da-fiscalizacao">A importância da
                                    fiscalização</router-link></li>
                            <li><a class="dropdown-item"
                                    href="https://crefsergipe.1doc.com.br/b.php?pg=wp/wp&itd=6">Denúncia</a></li>
                            <!-- <li><router-link class="dropdown-item" to="/competicoes-e-jogos">Competições e jogos</router-link></li> -->
                            <!-- <li><router-link class="dropdown-item" to="/beneficios-da-fiscalizacao">Benefícios da fiscalização</router-link></li> -->
                            <li><router-link class="dropdown-item" to="/orientacoes">Justificativas de
                                    infrações</router-link></li>
                            <!-- <li><router-link class="dropdown-item" to="/relatorios">Relatórios</router-link></li> -->
                            <!-- <li><router-link class="dropdown-item" to="/fiscalizacao-pessoa-fisica">Pessoa Física</router-link></li> -->
                            <!-- <li><router-link class="dropdown-item" to="/fiscalizacao-pessoa-juridica">Pessoa Jurídica</router-link></li> -->
                            <!-- <li><router-link class="dropdown-item" to="/fiscalizacao-estagiarios">Estagiários</router-link></li> -->
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-label="Legislação" to="/legislacao">Legislação</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-label="Notícias" to="/noticias">Notícias</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-label="Contato" to="/contato">Contato</router-link>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link autoatendimento" aria-label="Atendimento">Autoatendimento</button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div class="modal-autoatendimento">
        <div class="body-modal">
            <div class="btn-close-modal">
                <button class="btnClose">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                        <path d="M4.58984 14.0894L13.4667 5.21263" stroke="#8C8C8C" stroke-width="1.5" />
                        <path d="M4.58984 5.21262L13.4667 14.0894" stroke="#8C8C8C" stroke-width="1.5" />
                    </svg>
                </button>
            </div>
            <div class="titulo-modal text-center my-5">
                <h2>
                    Autoatendimento
                </h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 mt-3" v-for="(servico, index) in listServicos" :key="index">
                        <a :href="servico.link">
                            <div class="box-serv">
                                <div class="text-center">
                                    <img :src="servico.icone" alt="Autoatendimento">
                                    <div class="titulo-atendimento mt-4">
                                        <h3 v-html="servico.titulo"></h3>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

const URLSERVICOS = 'https://api.cref20.org.br/wp-json/cref/v1/autoatendimento';

export default {
    name: 'HeaderVue',
    route: {
        type: Object,
        required: true
    },
    methods: {
        modalFunctions() {
            let btnModal = document.querySelector('button.autoatendimento');
            let closeModal = document.querySelector('.btn-close-modal .btnClose');
            let modal = document.querySelector('.modal-autoatendimento');
            btnModal.addEventListener('click', () => {
                modal.classList.toggle('active');
            });
            closeModal.addEventListener('click', () => {
                modal.classList.toggle('active');
            })
        }
    },
    data() {
        return {
            listServicos: []
        }
    },
    mounted() {
        this.modalFunctions();

        axios.get(URLSERVICOS).then((response) => {
            this.listServicos = response.data.map((servico) => ({
                titulo: servico.titulo,
                icone: servico.icone,
                link: servico.link_servico
            }))
        })
    }
}
</script>

<style scoped>
.info-vermelho {
    background: #12575F;
    height: 4vh;
    display: flex;
    align-items: center;
}

.info-vermelho .informacoes-header {
    width: 100%;
}

.info-vermelho .informacoes-header span {
    color: #fff;
}

nav .navbar-collapse .navbar-nav .nav-item .nav-link {
    margin: 0 10px;
    color: #292929;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.nav-link.autoatendimento {
    color: #fff !important;
}

nav .navbar-collapse .navbar-nav .nav-item .nav-link.active::after {
    content: "";
    width: 23px;
    height: 5px;
    background-color: #45A047;
    display: block;
}

nav .navbar-collapse .navbar-nav .autoatendimento {
    color: #fff;
    background-color: #45A047;
    border-radius: 50px;
    padding: 15px;
    border: 2px solid #448945;
}

.modal-autoatendimento {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, .3);
    z-index: 1000;
    display: none;
}

.modal-autoatendimento.active {
    display: flex;
    align-content: center;
    justify-content: center;
}

.body-modal {
    position: relative;
    z-index: 1001;
    background: #fff;
    margin: 4rem 0;
    width: 62%;
    border-radius: 20px;
    padding: 0 73px;
}

.modal-autoatendimento .btn-close-modal {
    position: absolute;
    right: 25px;
    top: 18px;
}

.modal-autoatendimento .btn-close-modal .btnClose {
    border-radius: 50px;
    background: transparent;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #8C8C8C;
}

.box-serv {
    border-radius: 15px;
    border: 3px solid #FFF0F0;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal-autoatendimento a {
    text-decoration: none;
    color: #575757;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-autoatendimento h3 {
    font-size: 23px;
}

@media (max-width: 1390px) {
    .modal-autoatendimento .container {
        overflow: auto;
        height: 310px;
    }
}

@media (max-width: 760px) {
    .info-vermelho {
        height: 7vh;
    }

    .modal-autoatendimento .container {
        height: 461px;
    }

    .body-modal {
        padding: 0 23px;
    }
}
</style>