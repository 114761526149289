<template>
    <Breadcrumb />
    <section class="conselheiros">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 my-5">
                    <div class="titulo-page">
                        <h2>
                            {{ titulo }}
                        </h2>
                    </div>
                </div>

                <!-- <div class="col-lg-4 mt-3" v-for="(conselheiro, index) in conselheiros" :key="index">
                    <div class="box-conselheiros">
                        <div class="titulo-conselheiros">
                            <h6>
                                {{ conselheiro.cargo }}
                            </h6>
                        </div>

                        <div class="nome-funcionario">
                            <span>
                                {{ conselheiro.nome }}
                            </span>
                        </div>

                        <div class="numero-cref mt-4 mb-3">
                            <span>
                                {{ conselheiro.numero_cref }}
                            </span>
                        </div>
                    </div>
                </div> -->

                <!-- <img src="../assets/imgs/CONSELHEIROS.jpg" alt="Componentes do conselho"> -->
                <img :src="imagem" alt="Componentes do Conselho">

            </div>
        </div>
    </section>

    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none"
        preserveAspectRatio="none">
        <path
            d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z"
            fill="#EEEEEE" fill-opacity="0.8" />
    </svg>
    <Popup />
    <FooterInternas />
</template>

<script>
import axios from 'axios';

import FooterInternas from '@/components/FooterInternas/FooterInternas.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import Popup from '@/components/Popup/PopupComponent.vue';

const URLCONS = 'https://api.cref20.org.br/wp-json/cref/v1/conselheiros';

export default {
    name: 'ConselheirosPage',
    components: {
        FooterInternas,
        Breadcrumb,
        Popup
    }, data() {
        return {
            titulo: [],
            imagem: [],
            conselheiros: []
        }
    }, mounted() {
        axios.get(URLCONS).then((response) => {
            const data = response.data[0];
            // console.log(data, response.data[0]);
            this.titulo = data.titulo_conselheiros;
            this.imagem = data.imagem_conselheiros;
            // this.conselheiros = data.composicao.map((conselho) => ({
            //     cargo: conselho.cargo,
            //     nome: conselho.nome,
            //     numero_cref: conselho.numero_do_cref
            // }))
        })
    }
}
</script>

<style scoped>
.conselheiros .titulo-page h2 {
    color: #313131;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

span.vermelho {
    color: #F00;
}

.conselheiros .box-conselheiros {
    border: 1px solid #fcd2d2;
    padding: 15px;
    border-radius: 10px;
}

.conselheiros .box-conselheiros .titulo-conselheiros h6 {
    color: #12575F;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.conselheiros .box-conselheiros .nome-funcionario span {
    color: #575757;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.conselheiros .box-conselheiros .numero-cref span {
    background-color: #12575F;
    padding: 10px;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 50px;
}

svg {
    width: 100%;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }
}
</style>